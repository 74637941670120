import React from 'react';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText, ListItemButton,
} from '@mui/material';
import { Link, generatePath } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { useStyles } from '../style/Style';

const SideBar = ({ isShow, toggleSideBar }) => {
  const classes = useStyles();
  return (
    (
      <Drawer open={isShow} onClose={toggleSideBar}>
        <List>
          <ListItem>
            <Link className={classes.navLink} to={generatePath('/')}>
              <ListItemButton>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
      </Drawer>
    )
  );
};
export default SideBar;
