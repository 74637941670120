/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import wsapiFetch from '../../api_auth';
import {
  polygonsFromVals,
  viewFullWithTs,
  allDstGroupMetricsLength,
  firstXMetricNames,
} from './tsviz';

const TSMetricsGrid = ({ ws, startTs, endTs }) => {
  // mo (metrics order) is expected to be the same as ws.metric_list most of the time. But if extra,
  //   unexpected prometheus metrics appear in the getVMTimeseries() query results they'll be
  //   appended on the end of the array for that diagnostic source type in mo.
  // Object {diag_src_type => [mn1, mn2, ...], ...}
  //   There is no implied order for the diagnostic source type so far.
  // const [mo, setMo] = useState({});

  // loTs (laid out TS) All the ts objects from the timeseries db query merged in a
  //   diagnostic source type -> group -> metric -> server instance -> array of ts (for that dst +
  //   metric + server instance tuple).
  const [loTs, setLoTs] = useState([]);

  const sparklineH = 30;
  const sparklineW = 500;

  const getVMTimeseries = async () => {
    const tspanMs = endTs.getTime() - startTs.getTime();
    const maxTsSamples = 500;
    let resolution = 1; // 1s
    const forcedCoarserRez = Math.ceil((tspanMs / 1000) / maxTsSamples);
    if (forcedCoarserRez > resolution) {
      resolution = forcedCoarserRez;
    }

    // Fetch just the first 20 metrics to fill the first page fast if there will be a lot more
    //   metrics in total.
    const totalMcount = allDstGroupMetricsLength(ws.d_mg_m_lists);
    if (totalMcount > 100) {
      let fpUrl = `/ws/${ws.wsid}/ts?s=${startTs.getTime()}&e=${endTs.getTime()}&rz=${resolution}`;
      firstXMetricNames(ws.d_mg_m_lists, 20).forEach((mn) => {
        fpUrl = `${fpUrl}&mn=${encodeURIComponent(mn)}`;
      });
      const fpHttpResp = await wsapiFetch(fpUrl);
      const fpJsonResult = await fpHttpResp.json();
      const fpTs = {};

      fpJsonResult.data.result.forEach((m) => {
        const mn = m.metric.__name__;
        const tsDst = m.metric.job; // diag source type is being stored in "job" prom label
        if (!fpTs[tsDst]) {
          fpTs[tsDst] = {};
        }
        if (fpTs[tsDst][mn]) {
          fpTs[tsDst][mn].push(m);
        } else {
          fpTs[tsDst][mn] = [m];
        }
      });

      const mTsSubset1 = viewFullWithTs(ws.d_mg_m_lists, fpTs);
      setLoTs(mTsSubset1);
    }

    const url = `/ws/${ws.wsid}/ts?s=${startTs.getTime()}&e=${endTs.getTime()}&rz=${resolution}`;
    const httpResp = await wsapiFetch(url);
    const jsonResult = await httpResp.json();
    const newTs = {};

    const ur = {}; // For unexpected metrics (= not in wsSmy); we'll add them to mo at the end.
    jsonResult.data.result.forEach((m) => {
      const mn = m.metric.__name__;
      const tsDst = m.metric.job; // diag source type is being stored in "job" prom label
      if (!newTs[tsDst]) {
        newTs[tsDst] = {};
      }
      if (newTs[tsDst][mn]) {
        newTs[tsDst][mn].push(m);
      } else {
        newTs[tsDst][mn] = [m];
        if (!ur[tsDst]) {
          ur[tsDst] = [];
        }
        ur[tsDst].push(mn);
      }
    });
    // console.log(newTs);
    const nmo = ws.d_mg_m_lists;
    if (ur.length) {
      Object.keys(ur).forEach((dst) => {
        console.log(`Adding ${ur[dst].length} unexpected metrics to metric order list for ${dst}: ${JSON.stringify(ur)}`);
        nmo[dst] = [...nmo[dst], ...ur[dst]];
      });
      // console.log(nmo);
    }

    const z = viewFullWithTs(nmo, newTs);
    // console.log(y);
    setLoTs(z);

    // setMo(nmo);
  };

  useEffect(() => {
    getVMTimeseries(); // aysnc; updates the ts state var
  }, [ws, startTs, endTs]);

  useEffect(() => {
    // dummy func to trigger render
  }, [loTs]);

  return (
    <>
      <div style={{ whiteSpace: 'pre', display: 'none' }}>{JSON.stringify(loTs, null, 2)}</div>
      {
      loTs.length
        ? loTs.map(({ diagSourceType, mgroups }) => (
          <>
            <h2>{diagSourceType}</h2>
            {mgroups.map(({ groupName, metrics }) => (
              <>
                <h3>{groupName}</h3>
                {
                metrics && metrics.length
                && metrics.map(({
                  name, minMax, tsType, serverInstanceTsMetrics,
                }) => (
                  serverInstanceTsMetrics.map(({ instance, timeseries }) => (
                    <div className="ts-metric-row" key={`${name}:${instance}`}>
                      <span className="metric-name">{name}</span>
                      <span className="hostalias">{instance}</span>
                      <span className="graph">
                        <svg height={sparklineH} width={sparklineW}>
                          <line x1="0" x2="0" y1="0" y2={sparklineH} stroke="#a0a0a0" />
                          <line x1="0" x2={sparklineW} y1={sparklineH} y2={sparklineH} stroke="#a0a0a0" />
                          {timeseries.length
                            && timeseries.map((tsX) => polygonsFromVals(
                              sparklineH,
                              sparklineW,
                              startTs.getTime(),
                              endTs.getTime(),
                              tsX.values,
                              minMax,
                              tsType,
                            ))}
                        </svg>
                      </span>
                      <span className="min_max_smy">
                        {tsType === 'CONSTANT' ? minMax[0] : (minMax[0] !== null ? `${minMax[0]} - ${minMax[1]}${tsType === 'COUNTER' ? '/s' : ''}` : '')}
                      </span>
                    </div>
                  ))
                ))
                }
              </>
            ))}
          </>
        ))
        : (
          <>
            <h3>...</h3>
            <div className="ts-metric-row">
              <span className="metric-name">... ...</span>
              <span className="hostalias" />
              <span className="graph">
                <line x1="0" x2="0" y1="0" y2={sparklineH} stroke="#a0a0a0" />
                <line x1="0" x2={sparklineW} y1={sparklineH} y2={sparklineH} stroke="#a0a0a0" />
              </span>
              <span className="min_max_smy">...</span>
            </div>
          </>
        )
      }
    </>
  );
};

TSMetricsGrid.propTypes = {
  ws: PropTypes.shape({
    wsid: PropTypes.string.isRequired,
    metrics_list: PropTypes.objectOf(
      PropTypes.arrayOf(PropTypes.string),
    ),
  }).isRequired,
  startTs: PropTypes.instanceOf(Date).isRequired,
  endTs: PropTypes.instanceOf(Date).isRequired,
};

export default TSMetricsGrid;

/**
 * Todos related to the file:
 *
 * - Think on how to add (inclusive) host list filter too.
 * - Maybe filters other than the time range should be a generic label-matching system? Eg. rsname
 *   might be a label for a MongoDB FTDC sample.
 *
 * - Use react-window (or its predecessor react-virtualized) for the table to speed rendering.
 *
 * - If the length of the value array is longer than the width passed to plotSparkLine() it will
 *   have more than one point in at least some of the x-axis points. Need to choose and implement a
 *   policy on how to handle this gracefully. Eg. plot a single point that is the average.
 *
 * - Do a query for the first page of timeseries metrics, according to what is in the first x mo
 *   array items and render that quickly, then get the full set.
 *
 * - Use web worker to do the viewFullWithTs(nmo, newTs) javascript in a background thread.
 */
