import firebaseAuth from './firebase_init';

/**
 * Adds JWT Authorization header then fetches from ${REACT_APP_API_SVR_URL}/api/${apiSubPath}`.
 *   Returns a Promise<Response> like fetch.
 * @param {string} apiSubPath
 * @param {RequestInit} initOpts
 */
const wsapiFetch = async (apiSubPath, initOpts) => {
  const authToken = await firebaseAuth.currentUser.getIdToken(false);

  let nio = initOpts;
  if (!nio) {
    nio = { headers: { Authorization: `Bearer ${authToken}` } };
  } else if (!nio.headers) {
    nio.headers = { Authorization: `Bearer ${authToken}` };
  } else if (!(initOpts instanceof Object)) {
    throw new Error('initOpts passed to wsapiFetch() is not an object');
  } else {
    nio.headers.Authorization = `Bearer ${authToken}`;
  }

  // console.log(`fetching /api/${apiSubPath} with  Authorization = ${nio.headers.Authorization}`);

  return fetch(`${process.env.REACT_APP_API_SVR_URL}/api${apiSubPath}`, nio);
};

export default wsapiFetch;
