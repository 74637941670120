import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {
  Avatar, Badge, Card, CardContent, Typography, CardActions, CardHeader, Box,
  Fab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import { makeStyles } from '@mui/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ComputerIcon from '@material-ui/icons/Computer';
import Indenticon from 'react-identicons';
import AvatarGroup from '@mui/material/AvatarGroup';

const useStyles = makeStyles((theme) => ({
  table: {
    /* minWidth: 350, */
  },
  wsidIdenticon: {
    padding: '0 6px',
  },
  avatarSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  /* avatarDefaultSize uses theme spacing 5, apparently */
  avatarLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  /* Avatar styles for "server-types" property */
  mongodb: {
    backgroundColor: '#008800',
  },
  wsWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    marginTop: '1rem',
    flexWrap: 'wrap',
    gap: '20px',
  },
}));

/**
 * Ensure that all properties exist and are of the type that will prevent
 *   errors being thrown during the render of JSX table.
 *
 * This approach addresses errors that were already discovered or were easy to imagine,
 *   but anytime the code in the rendering part changes it could be incomplete again.
 * TODO: So perhaps it better to render the <Table> as a fragment in a try-catch block, before
 *   the components' return() call, and display any error that occurs from the catch() block.
 */
const ensureWSObjSchema = (wslist) => {
  const newWslist = [];
  wslist.forEach((wssmy) => {
    const x = { ...wssmy };
    let newSfl = [];
    if (x.sfl) {
      // Enforce unique values
      newSfl = x.sfl.filter((v, i, a) => a.indexOf(v) === i);
    }
    x.sfl = newSfl;
    if (!x.fc) {
      x.fc = 0;
    }
    if (!x.sic) {
      x.sic = 0;
    }
    if (x.ftr_s && !(x.ftr_s instanceof Timestamp)) {
      delete x.ftr_s;
    }
    if (x.ftr_e && !(x.ftr_e instanceof Timestamp)) {
      delete x.ftr_e;
    }
    newWslist.push(x);
  });
  return newWslist;
};

const timeRangeText = (wssmy) => {
  if (wssmy.ftr_s && wssmy.ftr_e) {
    const sstr = (new Date(wssmy.ftr_s.seconds * 1000)).toISOString().replace(/:..\....Z$/, 'Z');
    const estr = (new Date(wssmy.ftr_e.seconds * 1000)).toISOString().replace(/:..\....Z$/, 'Z');
    return `${sstr} - ${estr}`;
  }
  return '';
};

const WorkspaceList = ({ wslist, postNewWSRequest }) => {
  const classes = useStyles();

  const wsl = ensureWSObjSchema(wslist);
  const handlePostNewWSRequest = () => {
    postNewWSRequest();
  };
  return (
    <div className={classes.wsWrapper}>

      {wslist.map((wssmy) => (
        <Card key={wssmy.wsid}
          sx={{
            boxShadow: '0 0 2px rgb(104 85 224 / 20%)',
            width: '25vw',
            height: '30vh',
            bgcolor: 'rgb(247,248,251)',
            borderRadius: '15px',
          }}
        >
          <CardHeader
            avatar={(
              <Badge badgeContent={wssmy.fc}><FolderSharedIcon fontSize="large" sx={{ color: 'rgb(244,214,173)' }} />
              </Badge>
            )}
            action={(
              <AvatarGroup max={4}>
                {wssmy.sfl && wssmy.sfl.map((sftype) => (
                  <Avatar className={`${classes[sftype]} ${classes.avatarSmall}`} key={sftype} aria-label={sftype} aria-label="recipe">
                    {sftype.substr(0, 1).toUpperCase()}
                  </Avatar>
                ))}

              </AvatarGroup>

)}
          />
          <CardContent>
            <Link to={generatePath('/ws/:wsid/files', wssmy)}>
              <Indenticon string={wssmy.wsid} size={24} className={classes.wsidIdenticon} />
              <Typography sx={{ color: '#000000' }} variant="h6">{wssmy.wsid}</Typography>
            </Link>
            <Typography variant="body2">
              {wssmy.ftr_s && wssmy.ftr_e && timeRangeText(wssmy)}
            </Typography>
          </CardContent>
        </Card>

      ))}

      <Card
        sx={{
          width: '25vw',
          height: '30vh',
          borderRadius: '15px',
          border: '4px dashed #ede7f6',
          boxShadow: 'none',
        }}
      >

        <Box sx={{
          height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px',
        }}
        >
          <Fab onClick={handlePostNewWSRequest} sx={{ bgColor: 'rgba(0, 0, 0, 0.12)' }}>
            <AddIcon color="primary" />
          </Fab>
          <Typography variant="h7">Add Workspace</Typography>
        </Box>

      </Card>
    </div>
  );
};

WorkspaceList.propTypes = {
  wslist: PropTypes.arrayOf(
    PropTypes.shape({
      wsid: PropTypes.string,
      server_types: PropTypes.arrayOf(
        PropTypes.string,
      ),
      hosts: PropTypes.arrayOf(
        PropTypes.string,
      ),
      files: PropTypes.arrayOf(
        PropTypes.shape({
          path: PropTypes.string.isRequired,
          size: PropTypes.number.isRequired,
          mtime: PropTypes.string.isRequired,
          server_type: PropTypes.string,
          hosts: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
    }),
  ).isRequired,
};

export default WorkspaceList;

/**
 * Todos related to this file:
 *
 * - Change the workspace links to land on /ws/:wsid/timeseries rather than .../files when
 *   there is timeseries data present (and I guess a timespan in the workspace summary
 *   object is all that is needed to confirm that).
 *
 * - Using the MaterialUI icons feels like an unreasonable amount of dependencies, so it
 *   seems better to replace them with plain images or SVGs. Or font awesome icons?
 *   At any rate I wanted to put a better server icon in the <Badge> for wssmy.hosts.length
 *   and a file icon in the <Badge> for wssmy.files.length.
 */
