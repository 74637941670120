/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { TextField as FormikTextField } from 'formik-material-ui';
import { Button } from '@mui/material';
import { useStyles } from '../style/Style';
// From https://github.com/CSFrequency/react-firebase-hooks/tree/v5.0.0/auth#usesigninwithemailandpassword

const EmailPwdPwdCIForm = ({ initialValues, onSubmit, btnLabel }) => {
  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('A valid email address is required')
      .required('Required field'),
    password: Yup.string()
      .min(6, 'At least 6 characters long')
      .required('Required'),
    passwordci: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .min(6, '')
      .required('Required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form noValidate>
          <Field
            type="email"
            label="email"
            name="email"
            variant="outlined"
            fullWidth
            component={FormikTextField}
            className={classes.field}
          />
          <br />
          <Field
            type="password"
            label="password"
            name="password"
            variant="outlined"
            fullWidth
            component={FormikTextField}
            className={classes.field}
          />
          <br />
          <Field
            type="password"
            label="password (confirmation)"
            name="passwordci"
            variant="outlined"
            fullWidth
            component={FormikTextField}
            className={classes.field}
          />
          <br />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={!isValid || isSubmitting}
            className={`${classes.btn} ${classes.loginBtn}`}
          >
            {btnLabel}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
EmailPwdPwdCIForm.propTypes = {
  initialValues: PropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  btnLabel: PropTypes.string.isRequired,
};

const PwdPwdCIForm = ({ initialValues, onSubmit }) => {
  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'At least 6 characters long')
      .required('Required'),
    passwordci: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .min(6, '')
      .required('Required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form className={classes.pwResetForm} noValidate>
          <Field
            type="password"
            label="password"
            name="password"
            variant="outlined"
            fullWidth
            component={FormikTextField}
          />
          <br />
          <Field
            type="password"
            label="password (confirmation)"
            name="passwordci"
            variant="outlined"
            fullWidth
            component={FormikTextField}
          />
          <br />
          <Button
            sx={{
              border: '1px solid #06d6a0',
              color: '#000000',
              '&:hover': {
                border: '1px solid #06d6a0',
              },
            }}
            variant="outlined"
            color="primary"
            type="submit"
            fullWidth
            disabled={!isValid || isSubmitting}
          >
            Update password
          </Button>
        </Form>
      )}
    </Formik>
  );
};
PwdPwdCIForm.propTypes = {
  initialValues: PropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const EmailForm = ({ initialValues, onSubmit, btnLabel }) => {
  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('A valid email address is required')
      .required('Required field'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form noValidate>
          <Field
            type="email"
            label="email"
            name="email"
            variant="outlined"
            fullWidth
            component={FormikTextField}
            className={classes.field}
          />
          <Button
            sx={{
              border: '1px solid #06d6a0',
              color: '#000000',
              '&:hover': {
                border: '1px solid #06d6a0',
              },
            }}
            variant="outlined"
            color="primary"
            type="submit"
            fullWidth
            disabled={!isValid || isSubmitting}
          >
            {btnLabel}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
EmailForm.propTypes = {
  initialValues: PropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  btnLabel: PropTypes.string.isRequired,
};

export {
  EmailPwdPwdCIForm,
  PwdPwdCIForm,
  EmailForm,
};
