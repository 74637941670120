import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

const useStyles = makeStyles({
  wstime_range_selector: {
    padding: '0px 8px',
  },
});

function timeTextRender(value) {
  return (new Date(value)).toISOString();
}

const WSTimeRangeSelector = ({ ws, startEndTime, onSETimePairUpdate }) => {
  const classes = useStyles();
  // MaterialUI Slider expects the two-item array to be epoch ms numbers
  const nse = [startEndTime[0].getTime(), startEndTime[1].getTime()];

  return (
    <div className={classes.wstime_range_selector}>
      <Typography id="range-slider" gutterBottom>
        Time range available:
        {' '}
        {' '}
        {' '}
        {(new Date(ws.time_range.s)).toISOString()}
        {' '}
        {' '}
        {' '}
        -
        {' '}
        {' '}
        {' '}
        {(new Date(ws.time_range.e)).toISOString()}
      </Typography>
      <Slider
        value={nse}
        min={ws.time_range.s.getTime()}
        max={ws.time_range.e.getTime()}
        onChangeCommitted={(e/* event obj, ignoring */, newVal) => onSETimePairUpdate(newVal)}
        valueLabelDisplay="auto"
        valueLabelFormat={timeTextRender}
        aria-labelledby="range-slider"
        getAriaValueText={timeTextRender}
      />
      <div>
        Time range selected:
        {' '}
        {startEndTime[0].toISOString()}
        {' '}
        {' '}
        {' '}
        -
        {' '}
        {' '}
        {startEndTime[1].toISOString()}
      </div>
    </div>
  );
};

/**
 * The "time_range" property of the workspace is the workspace's total timespan; the "startEndTime"
 *   property is the user's selected time range to be displayed in the timeseries metric grid.
 */
WSTimeRangeSelector.propTypes = {
  ws: PropTypes.shape({
    time_range: PropTypes.shape({
      s: PropTypes.instanceOf(Date).isRequired,
      e: PropTypes.instanceOf(Date).isRequired,
    }),
  }).isRequired,
  startEndTime: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  onSETimePairUpdate: PropTypes.func.isRequired,
};

export default WSTimeRangeSelector;

/**
 * Todos for this component:
 *
 * - Consider changing it to a discrete slider that stops on units that are round sub-divisions of
 *   the total time range. Eg. if the total time range is 3 day's worth, the 'snap to' whole hours;
 *   if the range is down to 6 hrs then snap to 5 min interval points. Whatever unit that is between
 *   say 1/100th and 1/40th of the total range. And snap to round times on the clock, eg. if the
 *   start time is 08:34:13 and the 'steps' are 5 mins then snap to 08:35:00, 8:40:00 not 08:39:13,
 *   08:44:13.
 */
