/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import {
  Button, Link, Typography,
} from '@mui/material';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useHistory } from 'react-router-dom';
import firebaseAuth from '../../firebase_init';
import { useStyles } from '../style/Style';

// From https://github.com/CSFrequency/react-firebase-hooks/tree/v5.0.0/auth#usesigninwithemailandpassword

const SignInEmailPwdForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('A valid email address is required')
      .required('Required field'),
    password: Yup.string()
      .min(6, 'At least 6 characters long')
      .required('Required'),
  });

  const [
    signInWithEmailAndPassword,
    user,
    loading,
    error,
  ] = useSignInWithEmailAndPassword(firebaseAuth);
  /**
   * if user is logged in redirect to home
   */
  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [user]);
  const onSubmit = async (values) => {
    signInWithEmailAndPassword(values.email, values.password);
  };

  if (error) {
    return (
      <div>
        <p>
          Error:
          {error.message}
        </p>
      </div>
    );
  }
  if (loading) {
    return <p>Loading...</p>;
  }
  if (user) {
    // Helpful tip for debugging this app: once the user is loaded by the Firebase react hook
    // the top Router of the app may redirect the page away before this JSX segment gets rendered.
    return (
      <Typography>
        You&apos;re signed in!
        <br />
        Time to go the
        <Link to="/">top page</Link>
      </Typography>
    );
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        // eslint-disable-next-line no-unused-vars
        render={({ submitForm, values }) => (
          <Form noValidate className={classes.loginForm}>
            <Field
              type="email"
              label="email"
              name="email"
              variant="outlined"
              fullWidth
              component={TextField}
              className={classes.field}
            />
            <br />
            <Field
              type="password"
              label="password"
              name="password"
              variant="outlined"
              fullWidth
              component={TextField}
              className={classes.field}
            />
            <br />
            <Button
              type="submit"
              fullWidth
              className={`${classes.btn} ${classes.loginBtn}`}
            >
              Login
            </Button>
          </Form>
        )}
      />

    </div>
  );
};

export default SignInEmailPwdForm;
