import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button, Container, Dialog, DialogTitle, DialogContent, DialogActions, Divider,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import AddIcon from '@material-ui/icons/Add';
import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import wsapiFetch from '../../api_auth';
import { firestoreDb } from '../../firebase_init';
import WorkspaceList from './WorkspaceList';
import SiteTopBar from '../common/SiteTopBar';
import { useStyles } from '../style/Style';

const WorkspaceSelector = ({ firebaseUser }) => {
  const [wslist, setWSList] = useState([]);
  const [nWSSuccSbOpen, setNWSSuccSbOpen] = useState(false);
  const [wsCreateErr, setWSCreateErr] = useState(null);
  const [createdWSID, setCreatedWSID] = useState();
  const classes = useStyles();

  const firestoreFetchWSList = async () => {
    // N.b. Firestore API
    const wsmColl = collection(firestoreDb, 'ws_metadata');
    const qry = query(wsmColl, where('exl', 'array-contains', firebaseUser.uid));
    const querySnapshot = await getDocs(qry);
    const wsSmyMetaList = [];
    querySnapshot.forEach((doc) => {
      wsSmyMetaList.push(doc.data());
    });
    setWSList(wsSmyMetaList);
  };

  useEffect(() => {
    firestoreFetchWSList();
  }, []);

  const postNewWSRequest = async () => {
    const httpResp = await wsapiFetch('/ws', {
      method: 'post',
      body: JSON.stringify({ preferred_region: 'yyyy' }),
    });
    const doc = await httpResp.json();
    if (doc.wsid) {
      setWSList([{ just_created: true, ...doc }, ...wslist]);
      setCreatedWSID(doc.wsid);
    } else {
      setWSCreateErr(doc);
    }
    setNWSSuccSbOpen(true);
  };

  const handleNewWSDialogClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNWSSuccSbOpen(false);
    setWSCreateErr(null);
  };

  return (
    <>
      <SiteTopBar />
      <Container sx={{ mt: '.5rem' }}>
        <Typography variant="h3">Workspaces</Typography>
        {/* <Button className={`${classes.btn} ${classes.wsButton}`} onClick={postNewWSRequest} variant="contained" color="primary" startIcon={<AddIcon />}>
          Add new ...
        </Button> */}

        <div className={classes.titleUnderLine} />
        <WorkspaceList wslist={wslist} postNewWSRequest={postNewWSRequest} />
        <Dialog open={nWSSuccSbOpen} onClose={handleNewWSDialogClose}>
          <DialogTitle>{wsCreateErr ? 'Error' : 'New workspace created'}</DialogTitle>
          <DialogContent onClose={handleNewWSDialogClose} severity={wsCreateErr ? 'error' : 'success'}>
            {wsCreateErr
              ? <span>{JSON.stringify(wsCreateErr)}</span>
              : <span>Successfully created new workspace <Link to={`/ws/${createdWSID}/files`}>{createdWSID}</Link></span>}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNewWSDialogClose} color="primary">Close</Button>
            {!wsCreateErr && <Button color="primary" autoFocus><Link to={`/ws/${createdWSID}/files`}>Go to new workspace</Link></Button>}
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

WorkspaceSelector.propTypes = {
  firebaseUser: PropTypes.instanceOf({
    uid: '',
  }).isRequired,
};

export default WorkspaceSelector;

/**
 * Todos / backlog development ideas related to this file:
 *
 * - UI filter controls to make workspace selection faster for the user who can see many cases, eg.
 *   a user who is looking at a list of all public cases in a community site
 *   - I'd like to add a workspace keyword search auto-complete search input, probably above the
 *     workspace list. MaterialUI's <AutoComplete> for example.
 *   - Other filters that seem like a good idea a.t.m.
 *        - Type: MongoDB, OS, Postgresql, OpenTrace, etc.
 *        - Date(range)
 *        - User name / organization, by simple string match
 *        - Filename glob match
 *        - Restart (/Crash?) date
 * - The preferred_region option being added in new-workspace POST request
 *   is currently ignored server-side. It can be removed.
 */
