// import { xxx, yyy } from 'd3';

/**
 * Return a React fragment with a filled <polygon> element for each contiguous range of values.
 * The minimum and maximum values of each series will define the bottom and top of y-axis.
 * When the value is constant it will be a horizontal line in a 0.0 - 1.0 y range. At the bottom
 * if <= 0; at the top if >= 1; otherwise at the 0.0 - 1.0 value it is.
 */
const polygonsFromVals = (h, w, leftTs, rightTs, va, minMax, tsType) => {
  if (!va || !va.length) {
    return null;
  }
  const [vmin, vmax] = minMax;
  if (vmin === null) {
    console.log('!vmin case; returning empty polygon set. TODO make choice about eliminating or displaying these');
    return '';
  }

  const tspan = rightTs - leftTs; // ms
  const vrgh = vmax - vmin;
  const polygonStyle = { fill: 'gray', stroke: 'black', strokeWidth: 0.5 };

  // TODO if (tsType === 'CONSTANT') return minMax[0],0 -> minMax[0],w and return line.

  const polygonElems = [];
  let b = true;
  let pva = [];
  let lastV = null;
  let lastTs = null;
  let lastX = w;
  let firstX = null;
  for (let i = 0; i < va.length; i += 1) {
    const ts = va[i][0]; // sec, like the tspan argument
    const v = va[i][1];
    let rv = 0;
    if (tsType === 'COUNTER') {
      // Calculate rate() value
      if (lastV !== null) {
        rv = Math.round((v - lastV) / (ts - lastTs));
      }
    }
    const x = Math.floor((w * ((ts * 1000 /* ms */) - leftTs)) / tspan);
    if (v === null) {
      b = true;
      if (pva.length) {
        pva.push(x, h);
        polygonElems.push(<polygon key={i} points={pva} style={polygonStyle} />);
        pva = [];
      }
    } else if (lastV !== null) {
      // N.b. y = 0 for top, y = h for bottom
      // eslint-disable-next-line no-nested-ternary
      let y = null;
      if (tsType === 'COUNTER') {
        y = rv;
      } else {
        // eslint-disable-next-line no-nested-ternary
        y = vrgh === 0
          ? (v > 0 ? h * (1 - v) : h)
          : h - (Math.floor(h * ((v - vmin) / vrgh)));
      }
      if (Number.isNaN(y)) {
        break;
      }
      if (!firstX) {
        firstX = x;
        pva.push(firstX, h);
      }
      if (!pva.length) {
        pva.push(w, h);
      }
      pva.push(`${x},${y} `);
    }
    lastX = x;
    lastV = v;
    lastTs = ts;
  }
  if (pva.length) {
    pva.push(lastX, h);
    // console.log(pva);
    polygonElems.push(<polygon key={va.length} points={pva} style={polygonStyle} />);
  }

  return (
    <>
      { polygonElems }
    </>
  );
};
/**
 * No vmTsList argument will indicate this function to render a metric row, with a dummy
 *   server instance and no timeseries, for all metrics.
 */
const viewFullWithTs = (metricList, vmTsList) => {
  const r = {}; // TODO make r an array
  metricList.forEach((dg) => {
    const dst = dg.diagSourceType;
    if (!r[dst]) {
      r[dst] = { diagSourceType: dst, mgroups: [] };
    }
    dg.mgroups.forEach((grp) => {
      const mnList = grp.metrics;
      const gm = []; // metrics in this group, keeping same order.
      mnList.forEach((mObj) => {
        const mn = mObj.name;
        let tsType = null;
        if (vmTsList && vmTsList[dst] && vmTsList[dst][mn]) {
          const siTs = {};
          let minMax = [null, null];
          let vmin = null;
          let vmax = null;
          let rateMax = 0;
          let incrCount = 0;
          vmTsList[dst][mn].forEach((m) => {
            // Ajdust metric's vmin, vmax if its vals exceed them
            let lastV = null;
            let lastTs = null;
            m.values.forEach((vmVal) => {
              const ts = vmVal[0]; // from Prometheus; this is epoch secs
              const v = parseInt(vmVal[1], 10);
              if (tsType === null && lastV > v) { // TODO don't compare across restart points.
                tsType = 'GAUGE';
              }
              if (tsType === null && lastV !== null && v >= lastV) {
                const rt = Math.round((v - lastV) / (ts - lastTs));
                if (rt > rateMax) {
                  rateMax = rt;
                  // console.log(`rateMax = ${rateMax}`);
                }
                if (v > lastV) {
                  incrCount += 1;
                }
              }
              if (vmin === null || v < vmin) {
                vmin = v;
              }
              if (vmax === null || v > vmax) {
                vmax = v;
              }
              lastV = v;
              lastTs = ts;
            });

            minMax = [vmin, vmax];
            // TODO remove dupes in m's vals?

            if (!siTs[m.metric.instance]) {
              siTs[m.metric.instance] = {
                instance: m.metric.instance,
                // "minMax" : [.., ..], // per serverInstance minMax is not used (at least yet).
                timeseries: [m],
              };
            } else {
              siTs[m.metric.instance].timeseries.push(m);
            }
          });

          if (tsType === null) { // Implies not already detected to be a GAUGE
            if (vmin !== null && vmin === vmax) {
              tsType = 'CONSTANT';
            } else if (incrCount > 10) {
              // 10+ increases without a decrease is rough heuristic for deciding 'not guage'
              tsType = 'COUNTER';
              minMax = [0, rateMax];
              // console.log(`minMax set to ${JSON.stringify(minMax)} in COUNTER categorization
              // of ${mn}`);
            }
          }
          gm.push({
            name: mn,
            tsType,
            minMax,
            serverInstanceTsMetrics: Object.values(siTs),
          });
        } else {
          // If no vmTsList array defined we're going to display an empty row for the metric.
          gm.push({
            name: mn,
            tsType,
            minMax: [null, null],
            serverInstanceTsMetrics: [
              { instance: ' ', timeseries: [] },
            ],
          });
        }
      });
      r[dst].mgroups.push({ groupName: grp.groupName, metrics: gm });
    });
  });
  return Object.keys(r).map((k) => r[k]); // TODO make r an array
};

// eslint-disable-next-line arrow-body-style
const allDstGroupMetricsLength = (DGMList) => {
  return DGMList.map((dg) => dg.mgroups.map((g) => g.metrics.length).reduce((a, b) => a + b)).reduce((a, b) => a + b);
};

const firstXMetricNames = (DGMList, n) => {
  const r = [];
  for (let i = 0; i < DGMList.length && r.length < n; i += 1) {
    for (let j = 0; j < DGMList[i].mgroups.length && r.length < n; j + 1) {
      const mg = DGMList[i].mgroups[j];
      const ngml = mg.metrics.map((m) => m.name);
      r.push(...ngml);
    }
  }
  return r.slice(0, n);
};

export {
  polygonsFromVals,
  viewFullWithTs,
  allDstGroupMetricsLength,
  firstXMetricNames,
};
