/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { Button, Dialog, Typography } from '@mui/material';
import firebaseAuth from '../../firebase_init';
import { useStyles } from '../style/Style';

const DeleteAccountButton = () => {
  const [delDialogOpen, setDelDialogOpen] = useState(false);
  const classes = useStyles();
  const deletePrompt = async () => {
    await firebaseAuth.currentUser.delete();
    alert('Account deleted');
    // The auth change will be detected at the app router level, move to the public top page.
  };

  return (
    <div>
      <Button sx={{
        color: '#ff595e',
        borderColor: '#ff595e',
        '&:hover': {
          borderColor: '#ff595e',
          backgroundColor: '#ff595e',
          color: '#FFFFFF',
        },
      }}
        variant="outlined"
        onClick={() => setDelDialogOpen(true)}
        type="button"
      >
        Delete Account ...
      </Button>
      <Dialog open={delDialogOpen}>
        <Typography align="center" variant="h6" marginTop={2}> Delete account </Typography>
        <div className={classes.deleteDialog}>
          <Typography align="center" variant="h7" marginTop={2}>  This will remove the account, and there will be no recovery possible after.
            Continue?
          </Typography>
          <div className={classes.deleteDialogBtn}>
            <Button sx={{
              fontWeight: 'bold',
              height: '2.5rem',
              border: '1px solid #ef476f',
              color: '#ef476f',
              '&:hover': {
                color: '#FFFFFF',
                backgroundColor: '#ef476f',
                borderColor: '#ef476f',
              },
            }}
              variant="outlined"
              type="button"
              onClick={deletePrompt}
            >Yes, delete
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              type="button"
              onClick={() => setDelDialogOpen(false)}
            >Cancel
            </Button>
          </div>

        </div>
      </Dialog>
    </div>
  );
};

export default DeleteAccountButton;
