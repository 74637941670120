import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Divider, Typography, Button, Dialog, DialogActions,
} from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import firebaseAuth from '../firebase_init';
import SiteTopBar from './common/SiteTopBar';
import SignInSocialGoogleButton from './authui/SignInSocialGoogleButton';
import SignInSocialGithubButton from './authui/SignInSocialGithubButton';
import SignInEmailPwdForm from './authui/SignInEmailPwdForm';
import { EmailForm } from './authui/EmailPwdFormAsstd';
import { useStyles } from './style/Style';

const LoginPage = () => {
  const SiteTitle = 'logfile.support';
  const classes = useStyles();
  const [showPwdResetReqForm, setShowPwdResetReqForm] = useState(false);
  const [pwdResetReqErr, setPwdResetReqErr] = useState(null);

  const submitPwdResetEmailReq = async (values) => {
    try {
      await sendPasswordResetEmail(firebaseAuth, values.email, {
        url: `${window.location.origin}/signin`,
      });
      alert('Password reset request sent');
      setShowPwdResetReqForm(false);
    } catch (err) {
      setPwdResetReqErr(err.toString());
    }
  };

  const PwdResetEmailReqDialog = (
    <Dialog open={showPwdResetReqForm}>
      <div style={{ padding: '10px 20px' }}>
        {pwdResetReqErr
          ? { pwdResetReqErr }
          : (
            <EmailForm
              initialValues={{ email: '' }}
              onSubmit={submitPwdResetEmailReq}
              btnLabel="Sent password reset email.\nLook for email from &apos;noreply@logfile.support&apos; it might go into the spam folder."
            />
          )}
      </div>
      <DialogActions>
        <Button onClick={() => {
          setShowPwdResetReqForm(false);
          setPwdResetReqErr(null);
        }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <SiteTopBar centerTitle={SiteTitle} className={classes.topBar} />
      <div className={classes.main}>
        <div className={classes.container}>
          <div className={classes.loginContainer}>
            <SignInSocialGoogleButton overrideButtonText="Sign in with Google" />
            <SignInSocialGithubButton overrideButtonText="Sign in with Github" />
            <Divider className={classes.divider}> OR </Divider>
            <SignInEmailPwdForm />
            <Divider />
            <Button type="button" onClick={() => setShowPwdResetReqForm(true)}>Forgot password?</Button>
            {PwdResetEmailReqDialog}
            <div className={classes.signupContainer}>
              <Typography variant="h7" align="center">Don&apos;t have an account?</Typography>
              <Link to="/signup" className={`${classes.btn} ${classes.signupBtn}`}>
                Signup
              </Link>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;

/**
 * Todos related to the code in this file:
 *
 * - "Forgot password?" button should call code that uses Firebase's
 *    sendPasswordReset(email, {url: `${location.origin}/login`})
 * - Update password should call code that uses Firebase's
 *    updatePassword(newPassword)
 */
