import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  generatePath, Link, useParams, useRouteMatch, useLocation,
} from 'react-router-dom';
import {
  AppBar, Toolbar, Tabs, Tab, IconButton, Typography, Menu, MenuItem, Box, Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebaseAuth from '../../firebase_init';
import SignOutButton from '../authui/SignOutButton';
import UserAvatar from '../UserAvatar';
import SideBar from './SideBar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 3rem',
  },
  link: {
    color: '#000000',
    textDecoration: 'none',
    '&:visited': {
      color: '#000000',
    },
  },

}));

const SiteTopBar = ({ ws, centerTitle }) => {
  const classes = useStyles();
  const routeMatch = useRouteMatch();
  const routeParams = useParams();

  const [fbAuthUser, _fbAuthLoadingState, fbAuthError] = useAuthState(firebaseAuth);
  const [isShowSideBar, setIsShowSideBar] = useState(false);

  let tabRoutes = [];

  if (routeMatch.path === '/ws/:wsid') {
    tabRoutes = [
      { label: 'Files, Servers', routePath: `/ws/${routeParams.wsid}/files` },
      { label: 'Timeseries', routePath: `/ws/${routeParams.wsid}/timeseries` },
    ];
  }

  const location = useLocation();
  const matchCurrentPath = tabRoutes.find((r) => r.routePath === location.pathname);
  const tabValue = matchCurrentPath ? matchCurrentPath.routePath : false;

  let homeLink = <Link to={generatePath('/')}><HomeIcon /></Link>;
  if (routeMatch.path === '/') {
    homeLink = <HomeIcon color="disabled" />;
  }

  const [acctMenuAnchorEl, setAcctMenuAnchorEl] = useState(null);

  const handleAcctMenuIconClick = (event) => {
    setAcctMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAcctMenuAnchorEl(null);
  };
  const handleToggleSideBar = () => {
    setIsShowSideBar(!isShowSideBar);
  };
  return (
    <AppBar position="static" color="transparent" elevation={1}>
      <Toolbar variant="dense" className={classes.toolBar}>
        {fbAuthUser && <SideBar isShow={isShowSideBar} toggleSideBar={handleToggleSideBar} />}
        {
          fbAuthUser ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleToggleSideBar}
            >
              <MenuIcon />
            </IconButton>
          ) : homeLink
          }

        {ws
          ? (
            <Typography variant="h6" component="span" style={{ margin: '0px 10px' }}>
              Workspace
              {ws.wsid ? ws.wsid : '???'}
            </Typography>
          )
          : ''}
        {!ws && centerTitle
          ? (
            <Typography align="center" variant="h6" component="span" style={{ fontFamily: 'monospace', flexGrow: 1 }}>{centerTitle}</Typography>
          )
          : ''}
        {tabRoutes && tabRoutes.length
          ? (
            <Tabs className={classes.tabheaders} value={tabValue}>
              {tabRoutes.map((tab) => (
                <Tab
                  key={tab.routePath}
                  component={Link}
                  label={tab.label}
                  to={tab.routePath}
                  value={tab.routePath}
                />
              ))}
            </Tabs>
          )
          : ''}

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Account Info">
            <IconButton onClick={handleAcctMenuIconClick}
              style={{ padding: '0px' }}
            >
              <UserAvatar user={fbAuthUser} />
            </IconButton>
          </Tooltip>
          <Menu
            id="simple-menu"
            anchorEl={acctMenuAnchorEl}
            keepMounted
            open={Boolean(acctMenuAnchorEl)}
            onClose={handleAccountMenuClose}
            sx={{ mt: '25px' }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {fbAuthUser
              ? (
                <>
                  <MenuItem><Link className={classes.link} to="/account"><Typography textAlign="right">Account </Typography></Link></MenuItem>
                  <MenuItem sx={{ p: 0 }}> <SignOutButton variant="text" sx={{ p: '0 16px' }} /> </MenuItem>
                </>
              )
              : (
                <>
                  <MenuItem><Link className={classes.link} to="/login"><Typography textAlign="center">Log in</Typography></Link></MenuItem>
                  <MenuItem><Link className={classes.link} to="/signup"><Typography textAlign="center">Create account</Typography></Link></MenuItem>
                </>
              ) }
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

SiteTopBar.defaultProps = {
  ws: null,
  centerTitle: null,
};

SiteTopBar.propTypes = {
  ws: PropTypes.shape({
    wsid: PropTypes.string,
  }),
  centerTitle: PropTypes.string,
};

export default SiteTopBar;

/**
 * Todos related to this file
 *
 * - Display one of two different bars depending on whether there is a workspace context.
 *   Define JSX of common elements, then in the return fork on whether there is a wsid
 *   param or not.
 * - When a workspace determine the tab list dynamically because in the future different workspaces
 *   may have different tabs. Ones that only appear when certain diagnostic data is uploaded, and
 *   maybe also ones that are chosen by the users arbitrarily and which should be reloaded when the
 *   workspace is openened later, or by other users at the same time.
 * - Undecided architectural decision: should we use the hook to get the firebase auth user, as it
 *   currently does, or should it be received from the enclosing page components? Being null if the
 *   page is one of those served whilst user is unauthenticated.
 */
