/* eslint-disable react/button-has-type */
import React from 'react';
import { Typography } from '@mui/material';
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import firebaseAuth from '../../firebase_init';
import { EmailPwdPwdCIForm } from './EmailPwdFormAsstd';

const NewEmailVerifyLaunch = () => {
  const initialValues = {
    email: '',
    password: '',
    passwordci: '',
  };

  const emailVerificationOpts = {
    emailVerificationOptions: {
      url: `${window.location.origin}/signup`,
    },
    sendEmailVerification: true,
  };
  const [
    createEmailPwdCredsAndSendVerifyEmail,
    fbUser,
    loading,
    error,
  ] = useCreateUserWithEmailAndPassword(firebaseAuth, emailVerificationOpts);

  const onSubmit = async (values) => {
    if (!values.password || values.password !== values.passwordci) {
      alert('The password confirmation didn\'t match the first password entry value');
      return;
    }
    // The following executes these underyling firebase/auth functions:
    //  createUserWithEmailAndPassword()
    //  https://firebase.google.com/docs/reference/js/auth.md#createuserwithemailandpassword
    //  sendEmailVerification()
    //  https://firebase.google.com/docs/reference/js/auth.md#sendemailverification
    await createEmailPwdCredsAndSendVerifyEmail(values.email, values.password);
  };

  if (error) {
    return (
      <div>
        <p>
          Error:
          {error.message}
        </p>
      </div>
    );
  }
  if (loading) {
    return <p>loading...</p>;
  }
  if (fbUser) {
    return (
      <div>
        <Typography>
          Your email
          {fbUser.email}
          {' '}
          and password have been registered,
          but you&apos;ll need to click the link in the email to complete login.
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <EmailPwdPwdCIForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        btnLabel="Create account by email"
      />
    </div>
  );
};

export default NewEmailVerifyLaunch;
