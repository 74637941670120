import React from 'react';
import { StyledEngineProvider, createTheme } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import PageRoutes from './components/routes/PageRoutes';
import { useStyles } from './components/style/Style';

/**
 * The MaterialUI framework requires the roboto font to be imported at the Application scope.
 *   If we drop MaterialUI then remove these imports too. Or at least move it to them scope of
 *   components that use MaterialUI.
 * Per the MaterialUI documentation at https://material-ui.com/components/typography/ only the
 *   sizes 300, 400, 500 and 700 need to be loaded.
 */
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const App = () => {
  const theme = createTheme();

  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <div className={classes.root}>
          <PageRoutes />
        </div>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
