import { React, useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import {
  Container, Paper, Button, Dialog, DialogTitle, DialogActions,
} from '@mui/material';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SiteTopBar from '../common/SiteTopBar';
import { WSServerProcessList, WSFileList } from './WSFilesAndEnvironment';
import WSTimeRangeSelector from './WSTimeRangeSelector';
import TSMetricsGrid from './TSMetricsGrid';
import wsapiFetch from '../../api_auth';

/**
 * Change several known datetime properties which come as ISO-8601 strings or unix epoch ms in the
 * JSON response to Date objects.
 */
const convertWSDetailDateFields = (ws) => {
  if (ws.mtime) {
    // eslint-disable-next-line no-param-reassign
    ws.mtime = new Date(ws.mtime);
  }
  if (ws.time_range) {
    // eslint-disable-next-line no-param-reassign
    ws.time_range.s = new Date(ws.time_range.s);
    // eslint-disable-next-line no-param-reassign
    ws.time_range.e = new Date(ws.time_range.e);
  }
  ws.files.forEach((f) => {
    // eslint-disable-next-line no-param-reassign
    f.mtime = new Date(f.mtime);
    if (f.time_range) {
      // eslint-disable-next-line no-param-reassign
      f.time_range.s = f.time_range.s ? new Date(f.time_range.s) : null;
      // eslint-disable-next-line no-param-reassign
      f.time_range.e = f.time_range.e ? new Date(f.time_range.e) : null;
    }
  });
};

const Workspace = () => {
  const [ws, setWS] = useState(null);
  const [seTimePairValue, setTimePairValue] = useState(null);
  const [apiNetErr, setApiNetErr] = useState(null);
  const [delWholeWSBtnOpen, setDelWholeWSBtnOpen] = useState(false);
  const [delCompleteMsgOpen, setDelCompleteMsgOpen] = useState(false);

  const params = useParams();
  const location = useLocation();

  const fetchWorkspaceDetail = async (wsid) => {
    try {
      const apiRes = await wsapiFetch(`/ws/${wsid}`);
      if (!apiRes.ok) {
        let errData = {};
        try {
          errData = await apiRes.json();
        } catch (jdErr) { /* nothing */ }
        if (errData.err && errData.msg) { // WSAPI's err object has this format.
          setApiNetErr({ error: errData.msg });
        } else {
          setApiNetErr({ error: `API Fetch of workspace ${wsid} failed with an incomplete HTTP response.` });
        }
        setWS(null);
        return null;
      }
      const wsData = await apiRes.json();
      if (apiNetErr) { // clear previous error info, if one happened.
        setApiNetErr(null);
      }
      convertWSDetailDateFields(wsData);
      return wsData;
    } catch (err) {
      if (ws) {
        setWS(null);
      }
      setApiNetErr({ error: `Network request to https://wsapi.logfile.support/api/ws/${wsid} did not receive any HTTP response.` });
      return null;
    }
  };

  const postWholeWsDeleteRequest = () => {
    // Send HTTP DELETE request
    wsapiFetch(`/ws/${ws.wsid}`, {
      method: 'delete',
    })
      .then((res) => res.json())
      .then(() => {
        setDelWholeWSBtnOpen(false);
        setDelCompleteMsgOpen(true);
      });
  };

  const handleDelWholeWsDialogClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDelWholeWSBtnOpen(false);
    setDelCompleteMsgOpen(false);
  };

  useEffect(() => {
    const getWSDetail = async (wsid) => {
      const fetchedWs = await fetchWorkspaceDetail(wsid);
      if (fetchedWs) {
        setWS(fetchedWs);
        setTimePairValue([fetchedWs.time_range?.s, fetchedWs.time_range?.e]);
      }
    };
    getWSDetail(params.wsid);
  }, [params]);

  if (apiNetErr) {
    return (
      <>
        <SiteTopBar ws={ws} />
        <Container maxWidth="sm" style={{ padding: '40px 24px' }}>
          <Paper elevation={1} style={{ color: 'red', whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(apiNetErr, 2)}
          </Paper>
        </Container>
      </>
    );
  }

  return (
    <div>
      {
        ws && ws.wsid
          ? (
            <>
              <SiteTopBar ws={ws} />
              {location.pathname.match(/^\/ws\/[a-f0-9]+\/files/)
          && (
          <>
            <WSServerProcessList ws={ws} />
            <WSFileList ws={ws} />
            <p>
              <Button
                color="secondary"
                startIcon={<DeleteForeverIcon />}
                onClick={() => { setDelWholeWSBtnOpen(true); }}
              >
                Delete whole workspace
              </Button>
              <Dialog open={delWholeWSBtnOpen} onClose={handleDelWholeWsDialogClose}>
                <DialogTitle severity="warning">Delete whole workspace?</DialogTitle>
                <DialogActions>
                  <Button onClick={postWholeWsDeleteRequest} variant="contained" color="secondary">Yes, delete</Button>
                  <Button onClick={handleDelWholeWsDialogClose} color="primary">Cancel</Button>
                </DialogActions>
              </Dialog>
              <Dialog open={delCompleteMsgOpen} onClose={handleDelWholeWsDialogClose}>
                <DialogTitle severity="success">Deletion complete</DialogTitle>
                <DialogActions>
                  <Link to="/">Go to top page</Link>
                </DialogActions>
              </Dialog>
            </p>
          </>
          )}
              {seTimePairValue && seTimePairValue.length === 2 && location.pathname.match(/^\/ws\/[a-f0-9]+\/timeseries/)
          && (
          <>
            <WSTimeRangeSelector
              ws={ws}
              startEndTime={seTimePairValue}
              onSETimePairUpdate={
                (newVal) => setTimePairValue([new Date(newVal[0]), new Date(newVal[1])])
              }
            />
            <TSMetricsGrid ws={ws} startTs={seTimePairValue[0]} endTs={seTimePairValue[1]} />
          </>
          )}
            </>
          )
          : (
            <>
              <SiteTopBar />
              <div>{ws ? "Programming or runtime error: no 'wsid' id was received" : ''}</div>
            </>
          )
      }
    </div>
  );
};

export default Workspace;
