import { React } from 'react';
import PropTypes from 'prop-types';
import { User } from 'react-firebase-hooks/auth';
import { Container, Divider, Typography } from '@mui/material';
import SiteTopBar from './common/SiteTopBar';
import SignInSocialGoogleButton from './authui/SignInSocialGoogleButton';
import SignInSocialGithubButton from './authui/SignInSocialGithubButton';
import NewEmailVerifyLaunch from './authui/NewEmailVerifyLaunch';
import { useStyles } from './style/Style';

const SignUpPage = ({ firebaseUser }) => {
  const SiteTitle = 'logfile.support';
  const classes = useStyles();
  const ctrStyle = {
    width: '400px',
    border: '0.5px solid lightgrey',
    boxShadow: '2px 2px 4px lightgrey',
    padding: '5px',
  };

  if (firebaseUser) {
    if (!firebaseUser.emailVerified) {
      return (
        <>
          <SiteTopBar centerTitle={SiteTitle} />
          <div>
            <Container style={ctrStyle}>
              {firebaseUser.emailVerified
                ? (
                  <Typography>
                    Great. Email verified, you&apos;re all set. Time to go to / or /account.
                  </Typography>
                )
                : (
                  <Typography>
                    Initial email + password creation is complete.
                    <br />
                    An email has been sent to
                    {' '}
                    {firebaseUser.email}
                    .
                    <br />
                    Please click the link there to complete the sign-up process.
                  </Typography>
                )}
            </Container>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <SiteTopBar centerTitle={SiteTitle} />
      <div className={classes.main}>
        <div className={classes.container}>
          <div className={classes.loginContainer}>
            <SignInSocialGoogleButton overrideButtonText="Sign up with Google" />
            <SignInSocialGithubButton overrideButtonText="Sign up with Github" />
            <Divider className={classes.divider}> OR </Divider>
            <NewEmailVerifyLaunch />
          </div>
        </div>
      </div>
    </>
  );
};

SignUpPage.defaultProps = {
  firebaseUser: null,
};

SignUpPage.propTypes = {
  firebaseUser: PropTypes.instanceOf({
    email: '',
  }),
};

export default SignUpPage;
