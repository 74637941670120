/* eslint-disable react/button-has-type */
import React from 'react';
import { Button, Box } from '@mui/material';
import firebaseAuth from '../../firebase_init';

const SignOutButton = ({ sx, variant }) => (

  <Button sx={sx} variant={variant} color="secondary" onClick={() => firebaseAuth.signOut()} type="submit">
    Sign out
  </Button>

);

export default SignOutButton;
