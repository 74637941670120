import React, {
  useState, createRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { collection, query, onSnapshot } from 'firebase/firestore';
import {
  Table, TableHead, TableBody, TableRow, TableCell, Button,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField,
} from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import AddIcon from '@mui/icons-material/Add';
import wsapiFetch from '../../api_auth';
import { firestoreDb } from '../../firebase_init';

const uploadButtonAndInputStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    display: 'inline',
  },
  input: {
    display: 'none',
  },
}));

/**
 * Workaround file upload input + button composite component
 * https://v4.mui.com/components/buttons/#upload-button
 * Hopefully this, along with its styles above and PropTypes below,
 * can be re-used.
 */
const UploadButton = ({ onChangeHandler, inputRef }) => {
  const classes = uploadButtonAndInputStyles();

  return (
    <div className={classes.root}>
      <input
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        ref={inputRef}
        onChange={onChangeHandler}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="outlined"
          component="span"
          size="small"
          startIcon={<CloudUploadIcon />}
          style={{ margin: '0px 10px' }}
        >
          Add new ...
        </Button>
      </label>
    </div>
  );
};

UploadButton.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

const WSServerProcessList = ({ ws }) => (
  <div>
    <h4>
      {' '}
      Hosts (
      {ws.hosts.length}
      {' '}
      )
      {' '}
    </h4>
    <ul className="hostlist">
      {ws.hosts.map((hp) => (
        <li key={hp}>{hp}</li>
      ))}
    </ul>
  </div>
);

const WSFileList = ({ ws }) => {
  const [fileList, setFileList] = useState([]);
  const [delFileList, setDelFileList] = useState(new Set());
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [delSelFilesDialogOpen, setDelSelFilesDialogOpen] = useState(false);
  const [uplFileList, setUplFileList] = useState(null);
  const [subDirList, setSubDirList] = useState([]);
  const [subdirValid, setSubdirValid] = useState(true);
  let customSubDirPath = null;

  const fInputRef = createRef();
  const [addFilesDialogOpen, setAddFilesDialogOpen] = useState(false);

  const onFileDeleteCheckboxChange = (e, path) => {
    const newDelFileList = new Set(delFileList);
    if (e.target.checked) {
      newDelFileList.add(path);
    } else {
      newDelFileList.delete(path);
    }
    setDelFileList(newDelFileList);
    setShowDeleteBtn(newDelFileList.size > 0);
  };

  /**
   * Send a list of filepaths to the backend to suggest that it should check that the wsSmy cache,
   *   the actual files saved in the filesystem and the Firestore records are synced.
   * (Most helpful in the dev environment because the Firebase emulator loses all records when
   *   shutdown.)
   */
  const notifyFileRecDiscrepancies = async (filePaths) => {
    if (!filePaths || !filePaths.length) {
      return;
    }
    console.log(`Info: notifyFileRecDiscrepancies(${JSON.stringify(filePaths)})`);
    wsapiFetch(
      `/ws/${ws.wsid}/resync_firestore_filemd_recs`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          wsid: ws.wsid,
          file_paths: filePaths,
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res.updated_file_paths);
        if (res?.err) {
          console.log(`notifyFileRecDiscrepancies() received error response: ${JSON.stringify(res)}`);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('Error response from /ws/<wsid>/resync_firestore_filemd_recs: : ', err);
      });
  };

  const executeFileDeletion = (e) => {
    e.preventDefault();

    wsapiFetch(
      `/ws/${ws.wsid}/multi_file_delete`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          wsid: ws.wsid,
          file_paths: [...delFileList],
        }),
      },
    )
      .then((res) => res.json())
      // eslint-disable-next-line no-unused-vars
      .then((res) => {
        // console.log(`JSON res = ${JSON.stringify(res)}`);
        setDelSelFilesDialogOpen(false);
        setDelFileList(new Set());
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('Error response from /ws/<wsid>/multi_file_delete: : ', err);
      });
  };

  const handleDelSelFilesDialogClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDelSelFilesDialogOpen(false);
  };

  const uplFileUL = () => {
    if (uplFileList instanceof FileList) {
      // Verbose copying of the File objects to array "a" Only because the FileList object
      //   type doesn't seem to support array-like iteration.
      const a = [];
      for (let i = 0; i < uplFileList.length; i += 1) {
        a.push(uplFileList[i]);
      }
      return <ul>{a.map((f) => <li>{f.name}</li>)}</ul>;
    }
    return <ul><li>(none)</li></ul>;
  };

  const prepareFileUploadForm = (e) => {
    e.preventDefault();
    setUplFileList(fInputRef.current.files); // should == formData.getAll('File'); ?
    setAddFilesDialogOpen(true);
  };

  const sendFileUploadForm = () => {
    const formData = new FormData();
    formData.append('wsid', ws.wsid);
    // Note: Putting 'subdir' before any file fields so the backend can create the
    //   intended sub-directory before saving the new files to disk.
    if (customSubDirPath) {
      formData.append('subdir', customSubDirPath);
    }

    // N.b. as of Feb 2022 the formdata key name must be "File" for the sake of the matching WSAPI
    //   server's upload point. There is no connection to the fact these objects are of the "File"
    //   Web API class. The current multer options were written to process this exact field name.
    //   It can be changed, but do it in sync with changes to that API endpoint.
    for (let i = 0; i < uplFileList.length; i += 1) {
      formData.append('File', uplFileList[i]);
    }

    // N.b. To work with the multer expressjs middleware this is classic base64-encoded form data,
    //   not JSON like the other POST actions in this web app.
    // (Apparently there is a pure AJAX way to submit forms by explicit use of the FileReader web
    //   API: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest#submitting_forms_and_uploading_files
    //   but it is still described as experimental with IE only partially supporting it.
    //   I think it still arrives at the server the same base64 multipart formdata, unless we also
    //   change to sending as pure binary data instead. Sending as binary is also not univerally
    //   supported yet.)
    wsapiFetch(
      `/ws/${ws.wsid}/file-multiupload`,
      {
        method: 'POST',
        body: formData,
      },
    )
      .then((res) => res.json())
      // eslint-disable-next-line no-unused-vars
      .then((result) => {
        // console.log(res);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('File upload form submission error: ', err);
      });

    setAddFilesDialogOpen(false);
  };

  const handleAddFilesDialogClose = (_event, reason) => {
    setUplFileList(null);
    if (reason === 'clickaway') {
      return;
    }
    setAddFilesDialogOpen(false);
  };

  /**
   * Convert a firestor ws_files document to the javascript object. Two types of item need to be
   *   converted: the path, and any Timestamp objects.
   * Special note: the path is used as the Firestore document id because it is will be unique in a
   *   workspace. But the one character not allowed in the Firestore id string is forward slash '/'.
   *   It is replaced by '｜' (full-width vertical bar) in the backend's persistFileMdToFirestore().
   *   This is one spot in the front-end where that encoding is reversed.
   */
  const fsFileRecConverter = {
    // toFirestore: (x) => x, // Unused
    fromFirestore: (docSnapshot, options) => {
      const path = docSnapshot.id.replaceAll('｜', '/');
      const dsDoc = docSnapshot.data(options);
      if (dsDoc.time_range) {
        if (dsDoc.time_range?.s) {
          dsDoc.time_range.s = dsDoc.time_range.s.toDate();
        }
        if (dsDoc.time_range?.e) {
          dsDoc.time_range.e = dsDoc.time_range.e.toDate();
        }
      }
      if (dsDoc.mtime) {
        dsDoc.mtime = dsDoc.mtime.toDate();
      }
      // console.log({
      //   ...dsDoc,
      //   path,
      // });
      return {
        ...dsDoc,
        path,
      };
    },
  };

  const findFLDiscrepancies = (oldFl, currFL) => {
    const startFpaths = new Set(oldFl.map((f) => f.path));
    const currFPaths = new Set(currFL.map((f) => f.path));
    const newListFiles = currFL.filter((fdesc) => !startFpaths.has(fdesc.path));
    const oldListFiles = oldFl.filter((fdesc) => !currFPaths.has(fdesc.path));

    // This diff detection is currently only on the fields shown in this UI.
    //   For example it is not comparing md5sum.
    const diffDetect = (afd, bfd) => {
      const aMtime = afd.mtime instanceof Date ? afd.mtime.getTime() : 0;
      const bMtime = bfd.mtime instanceof Date ? afd.mtime.getTime() : 0;
      const ahosts = (afd.hosts ?? []).sort().join('');
      const bhosts = (bfd.hosts ?? []).sort().join('');
      const aTrS = afd.time_range?.s instanceof Date ? afd.time_range?.s.getTime() : null;
      const aTrE = afd.time_range?.e instanceof Date ? afd.time_range?.e.getTime() : null;
      const bTrS = bfd.time_range?.s instanceof Date ? bfd.time_range?.s.getTime() : null;
      const bTrE = bfd.time_range?.e instanceof Date ? bfd.time_range?.e.getTime() : null;
      return afd.size !== bfd.size
        || aMtime !== bMtime
        || aTrS !== bTrS || aTrE !== bTrE
        || ahosts !== bhosts
        || (afd.dst ?? null) !== (bfd.dst ?? null);
    };

    const diffFiles = oldFl.filter((ofd) => {
      const nfd = currFL.find((fdesc) => fdesc.path === ofd.path);
      if (!nfd) {
        return false;
      }
      const diff = diffDetect(ofd, nfd);
      // if (diff) {
      //   console.log('DEBUG: Different props for same file detected in findFLDiscrepancies()');
      //   console.log({ oldF: ofd, newF: nfd });
      // }
      return diff;
    });
    return [oldListFiles, newListFiles, diffFiles];
  };

  /**
   * This is a service for helping the backend by alerting when there are cache vs. firestore
   *   inconsistencies.
   * If some are found either the WS cache will be updated in the backend, and/or the records in
   *   the Firestore ws_files subcollection will be updated immediately.
   * (Most helpful in the dev environment because the Firebase emulator loses all records when
   *   shutdown.)
   */
  const auditWsSmyVsFBFileDiscrepancies = async () => {
    const [wsSmyF, firestoreF, diffF] = findFLDiscrepancies(ws.files, fileList);
    if (wsSmyF.length || firestoreF.length || diffF.length) {
      const woFp = wsSmyF.map((fd) => fd.path); // wsSmy-only filepaths
      const foFp = firestoreF.map((fd) => fd.path); // Firestore filepaths not in wsSmy.files
      const dFp = diffF.map((fd) => fd.path);
      await notifyFileRecDiscrepancies(woFp.concat(foFp).concat(dFp));
    }
  };

  // Load the Firestore snapshot of the file list
  // Dev note: modifying the content in items the state variable "fileList" array doesn't trigger
  //   new rendering, per React design. Modification of the array items is ignored until the top
  //   array reference is replaced with another.
  const loadFsFLSnapshot = async () => {
    const subcollRef = collection(firestoreDb, `ws_metadata/${ws.wsid}/ws_files`);
    const q = query(subcollRef).withConverter(fsFileRecConverter);

    let firstLoadComplete = false;
    onSnapshot(q, (qs) => {
      qs.docChanges().forEach((change) => {
        const dd = change.doc.data();
        // Adding a 'snapshot receive time' as a part of a method to guarantee re-render of React
        //  table row component for a file by using `${path}-${srtime} as the "key" prop.
        dd.srTime = new Date();
        dd.trKey = `${dd.path}-${dd.srTime.getTime()}`;
        if (change.type === 'added') {
          fileList.push(dd);
        }
        if (change.type === 'modified') {
          const delIdx = fileList.findIndex((fr) => fr.path === dd.path);
          if (delIdx >= 0) {
            fileList.splice(delIdx, 1);
          }
          fileList.push(dd);
        }
        if (change.type === 'removed') {
          const delIdx = fileList.findIndex((fr) => fr.path === dd.path);
          if (delIdx >= 0) {
            fileList.splice(delIdx, 1);
          }
        }
      });
      if (!firstLoadComplete) {
        auditWsSmyVsFBFileDiscrepancies();
        firstLoadComplete = true;
      }
      fileList.sort((a, b) => a.path > b.path);
      // Make new copy of filelist array object to trigger re-rendering.
      setFileList([...fileList]);
    });
  };

  useEffect(() => {
    const uniqSubDirPrefixes = [...(new Set(ws.files.map(
      (fd) => fd.path.replace(/[^/]*$/, ''),
    ).filter((s) => (s && !s.match(/^\/*$/)))))];
    setSubDirList(uniqSubDirPrefixes);

    loadFsFLSnapshot();
  }, []);

  const validateAndSetSubDir = (e, val, reason) => {
    if (reason === 'reset') {
      customSubDirPath = null;
      setSubdirValid(true); // empty = no dir = valid
      return;
    }
    // console.log(`validateAndSetSubDir(e, ${val}, ${reason})`);
    if (val.substring(0, 1) === '/' || val.match(/\.\./)) {
      // TODO re-enable after backend directory escape testing is done
      // customSubDirPath = null;
      // setSubdirValid(false);
    } else {
      customSubDirPath = val;
      setSubdirValid(true);
    }
  };

  const drResync = async (fpath) => {
    wsapiFetch(
      `/ws/${ws.wsid}/force_dst_redetection`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          wsid: ws.wsid,
          file_path: fpath,
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res?.err) {
          console.log(`notifyFileRecDiscrepancies() received error response: ${JSON.stringify(res)}`);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('Error response from /ws/<wsid>/force_dst_redetection: ', err);
      });
  };

  return (
    <>
      <h4>
        Files (
        {fileList.length}
        )
        <UploadButton inputRef={fInputRef} onChangeHandler={prepareFileUploadForm} />
        <Dialog open={addFilesDialogOpen} onClose={handleAddFilesDialogClose}>
          <DialogTitle>File(s) to upload</DialogTitle>
          <DialogContent onClose={handleAddFilesDialogClose} severity="info" style={{ width: '420px' }}>
            <Autocomplete freeSolo
              options={subDirList}
              onInputChange={validateAndSetSubDir}
              onChange={validateAndSetSubDir}
              renderInput={(params) => (
                <TextField error={!subdirValid} {...params} label="Subdirectory to upload in (optional)" margin="normal" />
              )}
            />
            {uplFileUL()}
          </DialogContent>
          <DialogActions>
            <Button onClick={sendFileUploadForm} color="primary">Upload</Button>
            <Button onClick={handleAddFilesDialogClose} color="secondary">Cancel</Button>
          </DialogActions>
        </Dialog>
      </h4>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Button disabled={!showDeleteBtn} aria-label="delete" onClick={() => setDelSelFilesDialogOpen(true)}>
                <DeleteIcon />
              </Button>
              <Dialog open={delSelFilesDialogOpen} onClose={handleDelSelFilesDialogClose}>
                <DialogTitle>New workspace created</DialogTitle>
                <DialogContent onClose={handleDelSelFilesDialogClose} severity="warning">
                  Delete the following
                  {' '}
                  {delFileList.size}
                  {' '}
                  files?
                  <ul>
                    {[...delFileList].map((path) => <li>{path}</li>)}
                  </ul>
                </DialogContent>
                <DialogActions>
                  <Button onClick={executeFileDeletion} color="primary">Delete</Button>
                  <Button onClick={handleDelSelFilesDialogClose} color="secondary">Cancel</Button>
                </DialogActions>
              </Dialog>
            </TableCell>
            <TableCell>File</TableCell>
            <TableCell align="right">Size</TableCell>
            <TableCell>Upload time</TableCell>
            <TableCell>Hosts</TableCell>
            <TableCell>Time range</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fileList.map((f) => (
            <TableRow key={f.trKey}>
              <TableCell>
                <input
                  type="checkbox"
                  onChange={(e) => onFileDeleteCheckboxChange(e, f.path)}
                />
              </TableCell>
              <TableCell>
                {f.path}
                <Button style={{ display: 'none' }} size="small" onClick={() => drResync(f.path)}>D.R.</Button>
              </TableCell>
              <TableCell>{f.size}</TableCell>
              <TableCell>{f.mtime.toISOString().replace(/\.[0-9]*(Z)?$/, 'Z')}</TableCell>
              <TableCell>{f.hosts ? f.hosts.join(', ') : ''}</TableCell>
              <TableCell>
                {f.time_range?.s && `${f.time_range.s.toISOString()} - ${f.time_range.e.toISOString()}`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

WSServerProcessList.propTypes = {
  ws: PropTypes.shape({
    hosts: PropTypes.arrayOf(
      PropTypes.string,
    ),
  }).isRequired,
};

WSFileList.propTypes = {
  ws: PropTypes.shape({
    wsid: PropTypes.string.isRequired,
    mtime: PropTypes.instanceOf(Date),
    files: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        server_type: PropTypes.string,
        hosts: PropTypes.arrayOf(PropTypes.string),
      }).isRequired,
    ),
  }).isRequired,
};

export {
  WSServerProcessList,
  WSFileList,
};

/**
 * Todos relate to this file:
 *   - Remove the <Button .... onClick={() => drResync(...)}> sometime. It is a temporary hack used
 *     for fixing things manually in dev and prod as the file metadata changes move from v3 to v4.
 */
