import { React } from 'react';
import {
  Container, Typography, Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import SiteTopBar from './common/SiteTopBar';

const topPageMuiStyle = makeStyles({
  root: {
    fontFamily: 'monospace',
  },
  middlecard: {
    borderRadius: 3,
    padding: '20px 30px',
    fontSize: '1.5em',
  },
});

const PublicTopPage = () => {
  const classesX = topPageMuiStyle();

  return (
    <>
      <SiteTopBar />
      <Container maxWidth="md" className={classesX.root}>
        <h1 style={{ textAlign: 'center' }}>
          <Typography variant="h1">
            {'{'}
            logfile:support
            {'}'}
          </Typography>
        </h1>
        <Container maxWidth="sm">
          <Paper elevation={1} className={`${classesX.root} ${classesX.middlecard}`}>
            <p>
              <Typography variant="p">
                Quickly find the
                <b>what</b>
                ,
                <b>where</b>
                {' '}
                and
                <b>when</b>
                {' '}
                from your MongoDB log and metric files.
              </Typography>
            </p>
            <p>
              <Typography variant="p">
                <Link to="/login">Sign in</Link>
                {' '}
                /
                <Link to="/signup">sign up</Link>
                ,
                and create a new workspace. Then add your log and/or metric files to see
                what&apos;s happening in them in a timeseries visualization.
              </Typography>
            </p>
            <p>
              <Typography variant="p">
                Next you can share your workspace securely with your remote trouble-shooting
                buddies. Let them see what you see, pronto.
              </Typography>
            </p>
          </Paper>
        </Container>
      </Container>
    </>
  );
};

export default PublicTopPage;
