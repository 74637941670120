import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: '100vw',
  },
  main: {
    height: '90vh',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    maxWidth: '450px',
    width: '100%',
    padding: '1.2rem',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
  },
  field: {
    paddingBottom: '15px !important',
  },
  socialBtn: {
    color: '#222',
    background: '#fff',
    border: 'solid 1px #eee',
    padding: '1.3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 1px 2px #00000020',
    '&:hover': {
      backgroundColor: '#F2F7F8',
    },
  },
  divider: {
    padding: '.5rem 0',
  },
  signupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btn: {
    backgroundColor: '#FFFFFF',
    padding: '1rem',
    boxShadow: '0 0 20px rgb(104 85 224 / 20%)',
    transition: '0.4s',
    fontWeight: 600,
    letterSpacing: 2,
    fontSize: 16,
    borderRadius: 4,
  },
  loginBtn: {
    color: '#6855E0',
    border: '1px solid #6855E0',
    '&:hover': {
      backgroundColor: '#6855E0',
      color: '#FFFFFF',
    },
  },
  signupBtn: {
    padding: '1.2rem 3rem',
    color: '#FFFFFF',
    backgroundColor: '#6855E0',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#6855E0',
      color: '#FFFFFF',
    },
  },
  wsButton: {
    color: '#000000',
    border: '1px solid #80ed99',
    '&:hover': {
      backgroundColor: '#80ed99',
      color: '#000000',
    },
  },

  titleUnderLine: {
    position: 'relative',
    width: '90%',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    height: '1px',
    margin: '1rem 0',
    '&::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: '#a8dadc',
      width: '10%',
      height: '6px',
      borderRadius: '5px',
      top: '-2px',
    },
  },
  navLink: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  userInfoWrapper: {
    padding: '0 .2rem',
  },
  flexBox: {
    display: 'flex',
    gap: '1rem',
  },
  profilePictureInfo: {
    padding: '1rem 0',
  },
  userAvatar: {
    alignItems: 'center',
  },
  nameEmail: {
    padding: '1rem 0',
  },
  auhtenticationOptionWrapper: {
    padding: '1rem 0',
  },
  socialAuth: {
    alignItems: 'center',
    padding: '1rem 0',
  },
  emailAuth: {
    padding: '1rem 0',
  },
  nonAuth: {
    padding: '1rem 0',
  },
  resteForm: {
    padding: '2rem',
  },
  pwResetForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    width: '30vw',
  },
  emailVerify: {
    padding: '.5rem',
    border: '1px solid #d98369',
  },
  deleteDialog: {
    width: '30vw',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  },
  deleteDialogBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '.8rem',
    paddingTop: '1rem',
  },

});
