// From https://github.com/CSFrequency/react-firebase-hooks/tree/v5.0.0/auth#usesigninwithgoogle
// Patch only uploaded 3 days earlier, I think the documentation is incomplete.
// I think this example is waiting for the inclusion of an extra variable's state hook ... maybe.

/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Typography, SvgIcon, Link,
} from '@mui/material';
import { useSignInWithGithub } from 'react-firebase-hooks/auth';
import { useHistory } from 'react-router-dom';
import firebaseAuth from '../../firebase_init';
import { useStyles } from '../style/Style';

/**
 * References used during this setup.
 * https://firebase.google.com/docs/auth/web/github-auth
 * https://docs.github.com/en/developers/apps/building-oauth-apps/authorizing-oauth-apps
 * (Referring the above for the  Github user identity request options - I expect
 *   react-firebase-hooks useSignInWithGithub()'s callback to do the multiple steps.)
 * 2022-01-27 I choose to limit the scope to just receiving information. I disabled 'allow_signup'
 *  because creating new user at github.com in the process of signing up for this site is confusing.
 *
 * https://github.com/CSFrequency/react-firebase-hooks/tree/v5.0.0/auth#usesigninwithgoogle
 * https://github.com/CSFrequency/react-firebase-hooks/tree/v5.0.0/auth/useSignInWithPopup.ts
 */

const SignInSocialGithubButton = ({ overrideButtonText }) => {
  // It seems I didn't need to specify these.
  // const githubOAuthScopes = ['read:user']; //T.b.h. not sure of the right scope string values
  // const gitHubOauthParameters = {
  //   client_id: process.env.REACT_APP_GITHUB_OAUTH_CLIENT_ID,
  //   allow_signup: false,
  // };
  const [signInWithGithub, user, loading, error] = useSignInWithGithub(firebaseAuth);
  const history = useHistory();
  const classes = useStyles();
  /**
   * if user is logged in redirect to home
   */
  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [user]);
  if (error) {
    return (
      <div>
        <p>
          Error:
          {error.message}
        </p>
      </div>
    );
  }
  if (loading) {
    return <Typography>Loading...</Typography>;
  }
  if (user) {
    // Helpful tip for debugging this app: once the user is loaded by the Firebase react hook
    // the top Router of the app may redirect the page away before this JSX segment gets rendered.
    return (
      <Typography>
        You&apos;re authenticated via Github!
        <br />
        Time to go the
        <Link to="/">top page</Link>
      </Typography>
    );
  }
  let buttonText = 'Sign In / Register with Github';
  if (overrideButtonText) {
    buttonText = overrideButtonText;
  }
  return (
    <Button variant="contained"
      onClick={() => signInWithGithub()}
      className={classes.socialBtn}
      startIcon={(
        <SvgIcon viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 .3a12 12 0 0 0-3.8 23.4c.6.1.8-.3.8-.6v-2c-3.3.7-4-1.6-4-1.6-.6-1.4-1.4-1.8-1.4-1.8-1-.7.1-.7.1-.7 1.2 0 1.9 1.2 1.9 1.2 1 1.8 2.8 1.3 3.5 1 0-.8.4-1.3.7-1.6-2.7-.3-5.5-1.3-5.5-6 0-1.2.5-2.3 1.3-3.1-.2-.4-.6-1.6 0-3.2 0 0 1-.3 3.4 1.2a11.5 11.5 0 0 1 6 0c2.3-1.5 3.3-1.2 3.3-1.2.6 1.6.2 2.8 0 3.2.9.8 1.3 1.9 1.3 3.2 0 4.6-2.8 5.6-5.5 5.9.5.4.9 1 .9 2.2v3.3c0 .3.1.7.8.6A12 12 0 0 0 12 .3" />
        </SvgIcon>
)}
    >
      {buttonText}
    </Button>
  );
};

SignInSocialGithubButton.defaultProps = {
  overrideButtonText: null,
};

SignInSocialGithubButton.propTypes = {
  overrideButtonText: PropTypes.string,
};

export default SignInSocialGithubButton;
