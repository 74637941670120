// From https://github.com/CSFrequency/react-firebase-hooks/tree/v5.0.0/auth#usesigninwithgoogle
// Patch only uploaded 3 days earlier, I think the documentation is incomplete.
// I think this example is waiting for the inclusion of an extra variable's state hook ... maybe.

/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Typography, SvgIcon, Link,
} from '@mui/material';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { useHistory } from 'react-router-dom';
import firebaseAuth from '../../firebase_init';
import { useStyles } from '../style/Style';

const SignInSocialGoogleButton = ({ overrideButtonText }) => {
  const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(firebaseAuth);
  const history = useHistory();
  const classes = useStyles();
  /**
   * if user is logged in redirect to home
   */
  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [user]);
  if (error) {
    return (
      <div>
        <p>
          Error:
          {error.message}
        </p>
      </div>
    );
  }
  if (loading) {
    return <Typography>Loading...</Typography>;
  }
  if (user) {
    // Helpful tip for debugging this app: once the user is loaded by the Firebase react hook
    // the top Router of the app may redirect the page away before this JSX segment gets rendered.
    return (
      <Typography>
        You&apos;re authenticated via Google!
        <br />
        Time to go the
        <Link to="/">top page</Link>
      </Typography>
    );
  }
  let buttonText = 'Sign In / Register with Google';
  if (overrideButtonText) {
    buttonText = overrideButtonText;
  }
  return (
    <Button className={classes.socialBtn}
      variant="contained"
      color="secondary"
      onClick={() => signInWithGoogle()}
      startIcon={(
        <SvgIcon viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
            <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z" />
            <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z" />
            <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z" />
            <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z" />
          </g>
        </SvgIcon>
)}
    >
      {buttonText}

    </Button>
  );
};

// Path for Github Icon (I think)
// eslint-disable-next-line max-len
// <path d="M12 .3a12 12 0 0 0-3.8 23.4c.6.1.8-.3.8-.6v-2c-3.3.7-4-1.6-4-1.6-.6-1.4-1.4-1.8-1.4-1.8-1-.7.1-.7.1-.7 1.2 0 1.9 1.2 1.9 1.2 1 1.8 2.8 1.3 3.5 1 0-.8.4-1.3.7-1.6-2.7-.3-5.5-1.3-5.5-6 0-1.2.5-2.3 1.3-3.1-.2-.4-.6-1.6 0-3.2 0 0 1-.3 3.4 1.2a11.5 11.5 0 0 1 6 0c2.3-1.5 3.3-1.2 3.3-1.2.6 1.6.2 2.8 0 3.2.9.8 1.3 1.9 1.3 3.2 0 4.6-2.8 5.6-5.5 5.9.5.4.9 1 .9 2.2v3.3c0 .3.1.7.8.6A12 12 0 0 0 12 .3" />

SignInSocialGoogleButton.defaultProps = {
  overrideButtonText: null,
};

SignInSocialGoogleButton.propTypes = {
  overrideButtonText: PropTypes.string,
};

export default SignInSocialGoogleButton;
