import { React, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Container, Dialog, DialogActions,
  FormControl, InputLabel, Input, TextField, Typography, Avatar, Divider, SvgIcon,
} from '@mui/material';
import {
  linkWithPopup, unlink, linkWithCredential, sendEmailVerification,
  GoogleAuthProvider, GithubAuthProvider, EmailAuthProvider,
  updateProfile, updatePassword, updateEmail,
} from 'firebase/auth';
// import { User } from 'react-firebase-hooks/auth';
import { DriveFileMove } from '@mui/icons-material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SiteTopBar from './common/SiteTopBar';
import { EmailPwdPwdCIForm, PwdPwdCIForm, EmailForm } from './authui/EmailPwdFormAsstd';
import SignOutButton from './authui/SignOutButton';
import DeleteAccountButton from './authui/DeleteAccountButton';
import { useStyles } from './style/Style';
import { google, github } from './common/svgs/svg';

const CreateAndLinkNewEmailPwd = ({ firebaseUser }) => {
  const initialValues = {
    email: firebaseUser.email, // In this page it would already be set by some provider
    password: '',
    passwordci: '',
  };

  const onSubmit = async (values) => {
    const credential = EmailAuthProvider.credential(values.email, values.password);
    await linkWithCredential(firebaseUser, credential);
    alert('Done - refresh page please');
    // const usercred = await linkWithCredential(firebaseUser, credential);
    // console.log('Account linking success', usercred.user);
  };

  return (
    <div>
      <EmailPwdPwdCIForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        btnLabel="Create and link password"
      />
    </div>
  );
};
CreateAndLinkNewEmailPwd.propTypes = {
  firebaseUser: PropTypes.instanceOf({
    email: '',
  }).isRequired,
};

const UserAccountPage = ({ firebaseUser }) => {
  const SiteTitle = 'logfile.support';
  const classes = useStyles();

  const [showEmailEditForm, setShowEmailEditForm] = useState(false);
  const [showAddPwdForm, setShowAddPwdForm] = useState(false);
  const [emailUpdErr, setEmailUpdErr] = useState(null);
  const [showPwdEditForm, setShowPwdEditForm] = useState(false);
  const [passwordUpdErr, setPasswordUpdErr] = useState(null);
  // "junk" is hack state val used to force re-render when the list of linked auth providers changes
  // A state val of the provider list would also work.
  const [junk, setJunk] = useState(1);

  let showUnlinkOptions = false;
  if (firebaseUser.providerData.length > 1) {
    showUnlinkOptions = true;
    console.log(showUnlinkOptions);
  }

  const GooglePd = firebaseUser.providerData.find((pd) => pd.providerId === 'google.com');
  const GithubPd = firebaseUser.providerData.find((pd) => pd.providerId === 'github.com');
  const PwdEmailPd = firebaseUser.providerData.find((pd) => pd.providerId === 'password');
  const incompleteEmailVerify = PwdEmailPd && !firebaseUser.emailVerified;

  const linkFA = async (providerId) => {
    let provider = null;
    if (providerId === 'google.com') {
      provider = new GoogleAuthProvider();
    } else if (providerId === 'github.com') {
      provider = new GithubAuthProvider();
    }
    await linkWithPopup(firebaseUser, provider);
    setJunk(junk + 1);
  };

  const unlinkFA = async (providerId) => {
    await unlink(firebaseUser, providerId);
    setJunk(junk + 1);
  };

  const LinkFAButton = (providerId) => (
    <div className={`${classes.flexBox} ${classes.socialAuth}`}>
      {providerId === 'google.com' ? (
        <SvgIcon viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          {google}
        </SvgIcon>
      ) : (
        <SvgIcon viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          {github}
        </SvgIcon>
      )}

      <Typography>
        Link authentication using {providerId}
      </Typography>
      <Button sx={{
        fontWeight: 'bold',
        height: '2.5rem',
        border: '1px solid #eeeeee',
      }}
        variant="outlined"
        onClick={() => linkFA(providerId)}
      >Authenticate
      </Button>
    </div>
  );
  const UnlinkFAButton = (providerId) => (
    <Button sx={{
      fontWeight: 'bold',
      height: '2.5rem',
      border: '1px solid #eeeeee',
      color: '#ff595e',
    }}
      onClick={() => unlinkFA(providerId)}
    >Unlink
    </Button>
  );

  const resendVerificationEmail = async () => {
    await sendEmailVerification(firebaseUser);
    alert(`Email sent to ${firebaseUser.email}`);
  };

  let origDispName = firebaseUser?.displayName;
  const saveNewDispName = async (newValue) => {
    if (origDispName !== newValue) {
      await updateProfile(firebaseUser, { displayName: newValue });
      origDispName = newValue;
    }
  };
  const submitEmailUpdate = async (formValues) => {
    try {
      await updateEmail(firebaseUser, formValues.password);
      alert('Password updated');
      setShowEmailEditForm(false);
    } catch (err) {
      setEmailUpdErr(err.toString());
    }
  };
  const submitPasswordUpdate = async (formValues) => {
    try {
      await updatePassword(firebaseUser, formValues.password);
      alert('Password updated');
      setShowPwdEditForm(false);
    } catch (err) {
      setPasswordUpdErr(err.toString());
    }
  };

  return (
    <>
      <SiteTopBar centerTitle={SiteTitle} />
      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >

        <Typography variant="h3">Identity</Typography>
        <div className={classes.userInfoWrapper}>

          <div className={classes.profilePictureInfo}>
            <Typography sx={{ mb: '.4rem' }} variant="h6">Profile Picture</Typography>
            <div className={`${classes.flexBox} ${classes.userAvatar}`}>
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 56, height: 56 }}
              />

            </div>
          </div>
          <div className={`${classes.flexBox} ${classes.nameEmail}`}>
            <FormControl fullWidth>
              <label> Name </label>
              <TextField
                defaultValue={firebaseUser?.displayName}
                onBlur={(e) => e && saveNewDispName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    saveNewDispName(e.target.value);
                  }
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <label> Email </label>
              <TextField
                defaultValue={firebaseUser.email}
                disabled
              />
            </FormControl>
          </div>
          <Divider />
          <div className={`${classes.auhtenticationOptionWrapper}`}>

            <Typography variant="h6">
              Authentications options
            </Typography>
            {GooglePd
              ? (
                <div className={`${classes.flexBox} ${classes.socialAuth}`}>
                  <SvgIcon viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                    {google}
                  </SvgIcon>
                  Login with Google enabled
                  {showUnlinkOptions && UnlinkFAButton('google.com')}
                </div>
              )
              : (<div>{LinkFAButton('google.com')}</div>)}
            {GithubPd
              ? (
                <div className={`${classes.flexBox} ${classes.socialAuth}`}>
                  <SvgIcon viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                    {github}
                  </SvgIcon>
                  Login with Github enabled
                  {showUnlinkOptions && UnlinkFAButton('github.com')}
                </div>
              )
              : (<div>{LinkFAButton('github.com')}</div>)}
            <Divider />
            {PwdEmailPd
              ? (
                <div className={classes.emailAuth}>

                  <div>
                    <Typography variant="h6">Login by password enabled.</Typography>
                    {incompleteEmailVerify
                    && (
                      <div>
                        <Typography variant="body2">
                          The email hasn&apos;t been verified yet.
                          <br />
                          You will not be able to login again via password until you verify
                          email account ownership by clicking the link in the email sent from
                          logfile.support.
                        </Typography>

                        <Button sx={{
                          m: '10px 2px',
                        }}
                          variant="contained"
                          type="button"
                          onClick={resendVerificationEmail}
                        >Resend verification email
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className={`${classes.flexBox} ${classes.emailAuth}`}>
                    <Button variant="outlined" type="button" onClick={() => setShowEmailEditForm(true)}>Change email address</Button>
                    <Dialog open={showEmailEditForm}>
                      <Typography align="center" variant="h6" marginTop={2}>
                        Change Email
                      </Typography>
                      <div className={classes.resteForm}>
                        {emailUpdErr
                          ? { emailUpdErr }
                          : (
                            <EmailForm
                              initialValues={{ email: firebaseUser?.email }}
                              onSubmit={submitEmailUpdate}
                              btnLabel="Send email update + verification email"
                            />
                          )}
                      </div>
                      <DialogActions>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={() => {
                            setShowEmailEditForm(false);
                            setEmailUpdErr(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Button variant="outlined" type="button" onClick={() => setShowPwdEditForm(true)}>Change password</Button>
                    <Dialog open={showPwdEditForm}>
                      <Typography align="center" variant="h6" marginTop={2}>
                        Change Password
                      </Typography>
                      <div className={classes.resteForm}>
                        {passwordUpdErr
                          ? { passwordUpdErr }
                          : <PwdPwdCIForm initialValues={{}} onSubmit={submitPasswordUpdate} />}
                      </div>
                      <DialogActions>
                        <Button color="secondary" variant="outlined" onClick={() => setShowPwdEditForm(false)}>Cancel</Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              )
              : (
                <>
                  {showAddPwdForm
                    ? <CreateAndLinkNewEmailPwd firebaseUser={firebaseUser} />
                    : (
                      <div className={`${classes.flexBox} ${classes.socialAuth}`}>
                        {/* ForwardToInboxIcon */}
                        <ForwardToInboxIcon />
                        <Typography>
                          Add login by password (+ email) authentication.
                        </Typography>

                        <Button sx={{
                          fontWeight: 'bold',
                          height: '2.5rem',
                          border: '1px solid #eeeeee',
                        }}
                          onClick={() => setShowAddPwdForm(true)}
                        >
                          Authenticate
                        </Button>
                      </div>
                    )}
                </>
              )}
            <Divider />
            <div className={`${classes.flexBox} ${classes.nonAuth}`}>
              <SignOutButton variant="outlined" />
              <DeleteAccountButton />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

UserAccountPage.propTypes = {
  firebaseUser: PropTypes.instanceOf({
    email: '',
  }).isRequired,
};

export default UserAccountPage;

/**
 * Todos related to the code in this file.
 *
 * - Add change password and change email edit options to the password authentication
 *   if it is used. According to firebase documentation these require doing a re-auth
 *   if the login session has been open for more than a short time.
 */
