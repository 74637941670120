/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebaseAuth from '../../firebase_init';
import Workspace from '../workspaces/Workspace';
import WorkspaceSelector from '../workspaces/WorkspaceSelector';
import PublicTopPage from '../PublicTopPage';
import LoginPage from '../LoginPage';
import SignUpPage from '../SignUpPage';
import UserAccountPage from '../UserAccountPage';
import SiteTopBar from '../common/SiteTopBar';

/**
 * The MaterialUI framework requires the roboto font to be imported at the Application scope.
 *   If we drop MaterialUI then remove these imports too. Or at least move it to them scope of
 *   components that use MaterialUI.
 * Per the MaterialUI documentation at https://material-ui.com/components/typography/ only the
 *   sizes 300, 400, 500 and 700 need to be loaded.
 */
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const PageRoutes = () => {
  // eslint-disable-next-line no-unused-vars
  const [fbAuthUser, fbLoading, fbAuthError] = useAuthState(firebaseAuth);

  if (fbLoading) {
    // Dev note: wrapping in a <Route> because SiteTopBar uses that context
    return (
      <Router>
        <Route path="*">
          <SiteTopBar />
        </Route>
      </Router>
    );
  }

  if (fbAuthError) {
    return (
      <div>
        Firebase authentication Error: `$
        {JSON.stringify(fbAuthError)}
        `
      </div>
    );
  }

  // When password-authorized user has created an account but hasn't yet verified by email link
  // Only expected during the initial signup process, and only if not using federated auth.
  const incompleteEmailVerify = fbAuthUser && !fbAuthUser.emailVerified
    && fbAuthUser.providerData.length === 1
    && fbAuthUser.providerData.find((pd) => pd.providerId === 'password');
  if (incompleteEmailVerify) {
    return (
      <Router>
        <Switch>
          <Route path="/signup" render={(props) => <SignUpPage {...props} firebaseUser={fbAuthUser} />} />
          <Route path="/"><Redirect to="/signup" /></Route>
        </Switch>
      </Router>
    );
  }

  if (fbAuthUser) {
    return (
      <Router>
        <Switch>
          <Route path="/" exact render={(props) => <WorkspaceSelector {...props} firebaseUser={fbAuthUser} />} />
          <Route path="/ws/:wsid" component={Workspace} />
          <Route path="/account" exact render={(props) => <UserAccountPage {...props} firebaseUser={fbAuthUser} />} />
          <Route path="/login"><Redirect to="/" /></Route>
          <Route path="/signup"><Redirect to="/" /></Route>
        </Switch>
      </Router>
    );
  }

  return (
    <Router>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/signup" component={SignUpPage} />
        <Route path="/"><PublicTopPage /></Route>
      </Switch>
    </Router>
  );
};

export default PageRoutes;
