/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

// eslint-disable-next-line no-unused-vars
const UserAvatar = ({ user }) => {
  const classes = useStyles();
  if (user) {
    if (user.photoURL) {
      return (
        <Avatar alt={user?.displayName} src={user.photoURL} className={classes.small} />
      );
    }
    let userInitials = '?';
    // N.b. expected email to always be set, but it can unset when password provider is unlinked
    if (user.email) {
      userInitials = user.email.substring(0, 1).toUpperCase();
    }
    if (user.displayName) {
      const leadingCaps = user.displayName.split(' ').slice(0, 2).map((token) => (
        token.substring(0, 1).toUpperCase()
      ));
      userInitials = leadingCaps.join('');
    }
    return (
      <Avatar alt={user?.displayName} className={classes.small}>{userInitials}</Avatar>
    );
  }

  return (
    <Avatar className={classes.small}>
      <AccountCircle />
    </Avatar>
  );
};

UserAvatar.defaultProps = {
  user: null,
};

UserAvatar.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    photoURL: PropTypes.string,
  }),
};

export default UserAvatar;
